<template>
  <div>
    <div class="title-table d-flex justify-content-between">
      <h4>{{ useSkinCareProfessionalRecommendation ? 'SkinCare Professional Recommendations' : 'RegimenLogic™ Recommendations' }}</h4>
      <router-link
        v-if="allowEdit"
        class="action-top"
        :to="{
          name: 'PatientChangeRegimen',
          params: {
            id: patient.id
          }
        }"
      >
        Change Recommendations
      </router-link>
    </div>

    <div
      v-if="patient.regimen_from_quiz"
      class="wrap-table border-table text-center"
    >
      <span class="d-block pb-2">No products have been added</span>
      <router-link
        :to="{
          name: 'PatientChangeRegimen',
          params: {
            id: patient.id
          }
        }"
        class="action-top"
      >
        Create New Regimen to Replace Online Skincare Assessment
      </router-link>
    </div>
    <div
      v-if="!patient.regimen_from_quiz"
      class="wrap-table figs-box"
    >
      <table
        v-if="selectedProducts.length > 0"
        class="table table-box"
      >
        <thead v-if="selectedProducts.length !== 0">
          <tr>
            <th />
            <th class="details-col">
              Product
            </th>
            <th class="multi-lines">
              <div class="row">
                <div
                v-if="useSkinCareProfessionalRecommendation"
                class="col-5">
                  Order Frequency
                </div>
                <div
                v-if="useSkinCareProfessionalRecommendation"
                class="col-3">
                  Price
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="selectedProducts.length == 0">
            <td class="text-center pt-4 pb-4">
              No products have been added
            </td>
          </tr>
          <tr 
            v-for="(product,index) in selectedProducts"
            v-else
            :key="product.variant_id + index"
          >
            <td>
              <a v-if="product.handle"
                :href="`${shopifyURL}/products/${product.handle}`"
                target="_blank"
              >
                <figure
                  v-if="product.image"
                  class="product-image"
                >
                  <span class="badge-counter" />
                  <figcaption
                  v-if="product.recomendation_type"
                  >{{ renderRecommendationType(product.recomendation_type) }}</figcaption>
                  <img
                    :src="product.image"
                    alt="product 1"
                  >
                </figure>
              </a>
              <span
                v-if="product.sku"
                class="img-detail"
              >
                SKU: {{ product.sku }}</span>
            </td>
            <td>
              <h6 v-if="product.title">{{ product.title }}</h6>
              <p  v-if="product.vendor" class="product-info s">
                {{ product.vendor }}
                <span v-if="product.variant_title && product.variant_title != 'Default Title'">| {{ product.variant_title }}</span>
              </p>
              <p
                v-if="product.time_of_use"
                class="when-to-apply mb-1"
              >
                When to apply: {{ product.time_of_use }}
              </p>
              <p
                v-if="product.usage_instruction"
                class="usage_instruction mb-0"
              >
                Usage Instructions: {{ product.usage_instruction | truncate(30, true) }}
              </p>
            </td>
            <td>
              <div class="row">
                <div class="col-5">
                  <div
                    v-if="useSkinCareProfessionalRecommendation && product.subscription"
                  >
                    {{ `Every ${product.subscription.order_interval_frequency} Days` }}
                    <span
                      :class="product.subscription.status == 'cancelled' ?
                        'subscription-pill cancelled' :
                        'subscription-pill'"
                    >{{ getSubscriptionPill(product.subscription) }}</span>
                  </div>
                </div>
                <div v-if="product.price" class="col-3">
                  ${{ product.price }}
                </div>
                <div class="col-3">
                  <a
                    v-if="product.available && allowEdit"
                    class="col-3 link_action hover-btn"
                    :disabled="sending"
                    @click.prevent="editProductModal(product.shopify_product_graph_id, product)"
                  >Edit</a>

                  <a
                    v-else-if="allowDelete"
                    class="col-3 link_action hover-btn"
                    :disabled="sending"
                    @click="removeProduct(product)"
                  >Remove</a>
                </div>
              </div>

              <inline-notification
                v-if="!product.available"
                type="warning"
                extra-class="out-of-stock"
              >
                <span class="font-normal">Product no longer available. Please
                  <a
                    class="hover-btn"
                    @click="removeProduct(product)"
                  >remove from regimen</a> or
                  <router-link
                    v-if="!patient.regimen_from_quiz"
                    class="hover-btn"
                    :to="{
                      name: 'PatientChangeRegimen',
                      params: {
                        id: patient.id
                      }
                    }"
                  >
                    change regimen
                  </router-link>
                </span>
              </inline-notification>
            </td>
          </tr>
          <tr
            v-if="selectedProducts.length"
          >
            <td colspan="2" />
            <td v-if="useSkinCareProfessionalRecommendation">
              <div class="row">
                <div class="col-5">
                  <strong>Subtotal</strong>
                </div>
                <div class="col-3">
                  <strong>${{ formatMoney(calcSubtotal()) }}</strong>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <span
        v-else
        class="pt-4 pb-4 text-center d-block"
      >No products have been added</span>
    </div>

    <Modal
      :show-modal="showModal"
      :modal-type="'popup-lg'"
      modal-id="add_product"
      @hide-modal-window="showModal = false"
    >
      <ProductModal
        :key="showModal"
        :product-id="productId"
        :modal-action="modalAction"
        :selected-product="selectedProduct"
        @remove-product="removeProduct"
        @hide-modal-window="showModal=false"
        @product-selected="productSelected"
      />
    </Modal>
    <div
      v-if="showModal"
      class="modal-backdrop fade show"
    />
  </div>
</template>

<style lang="scss" scoped>
  table {
    background-color: #fff;
  }
  table th {
    color: #fff;
  }
  .subscription-pill {
    display: inline-block;
    background-color: #32384f;
    border-radius: 50px;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    padding: 2px 7px;
    margin-top: 6px;
    text-align: center;
    text-transform: uppercase;
    &.cancelled {
      background-color: #eaebed;
      color: #7a7a7a;
    }
  }
</style>

<script>
import Modal from '../partials/Modal';
import ProductModal from '../partials/ProductModal';
import { formatMoney, renderRecommendationType } from '@/helper';
import { RegimenService } from '@/services/api.service';
import InlineNotification from '@/views/components/InlineNotification';
import { shopifyURL } from '@/services/config';
import { PatientsService } from '@/services/api.service';

// @group Patinet
export default {
  name: 'PatientViewRegimen',

  components: {
    ProductModal,
    Modal,
    InlineNotification
  },

  props: {
  patient: {
    type: Object,
    default() {
      return {};
    }
  },
  allowEdit: {
    type: Boolean,
    default: true
  },
  allowDelete: {
    type: Boolean,
    default: true
  },
  useSkinCareProfessionalRecommendation: {
    type: Boolean,
    default: true
  }
},


data() {
  return {
    products: [],
    sending: false,
    showModal: false,
    productId: null,
    regimenId: null,
    selectedProduct: null,
    selectedProducts: [],
    modalAction: 'update',
    shopifyURL: `https://${shopifyURL}`
  };
},

  watch: {
    patient: {
      handler: 'fillData',
      immediate: true,
    }
  },

  methods: {
    renderRecommendationType,
    async getRegimenProducts(patientId, regimen) {
      try {
        const response = await PatientsService.getRegimenProducts(regimen, patientId);
        if (response.status === 200 && response.headers['content-type'] && response.headers['content-type'].includes('application/json')){
          return response.data.resultData;
        }
      } catch (error) {
        return null;
      }
    },

    // @vuese
    // Used to fill local store date
    async fillData() {
      this.patientId = this.$route.params.id;
      console.log(this.patient);
      let regimen;
      let use_regimen = false;
      if(this.useSkinCareProfessionalRecommendation) {
        regimen = this.patient.regimen;
        use_regimen = true;
      } else {
        regimen = this.patient.rl_regimen;
      }

      if (regimen && regimen.products.length) {
        this.regimenId = regimen.id;
        this.shopify_product_ids = await this.getRegimenProducts(this.patientId, use_regimen);
        this.selectedProducts = regimen.products;
        this.selectedProducts = this.selectedProducts.filter(product => {
          // Remove product if not available
          return product.available ? true : false;
        });
        this.selectedProducts.sort((a, b) => {
          const shopifyProductIdA = a.shopify_product_id;
          const shopifyProductIdB = b.shopify_product_id;
          const indexA = this.shopify_product_ids.indexOf(shopifyProductIdA);
          const indexB = this.shopify_product_ids.indexOf(shopifyProductIdB);
          return indexA - indexB;
      });
        console.log(this.selectedProducts)
      }

      },

    // @vuese
    // Calc total price of all products
    calcSubtotal() {
      return this.selectedProducts.reduce((sum, product) => {
        return sum + (product.price ? parseFloat(product.price) : 0);
      }, 0);
    },
    // @vuese
    // Used format money
    // @arg Price value
    formatMoney (val) {
      return formatMoney(val);
    },
    // @vuese
    // Used to add product to selected products
    // @arg product
    productSelected(value) {
      if (this.sending) return;

      /// remove patient form product to prevent circular JSON structure
      delete value.patient;

      this.sending = true;

      this.showModal = false;
      const productIndex = this.selectedProducts.findIndex(product => {
        return product.variant_graph_id == value.variant_graph_id;
      });
      if (productIndex !== -1) {

        // value.id = this.selectedProducts[productIndex].id;

        this.updateProduct(value, this.regimenId)
          .then(() => {
            this.selectedProducts.splice(productIndex, 1, value);
            this.flashSuccess('You updated product');
          })
          .catch((e) => {
            if(e.response && e.response.data && e.response.data.errors){
              this.flashError(e.response.data.errors);
            } else {
              this.flashError('Error updating product');
            }
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },
    // @vuese
    // Used to update product in regimen
    // @arg Product object
    // @arg / Regimen ID
    updateProduct(product, regimenId) {
      return RegimenService.updateProduct({
        product: product,
      }, regimenId, product.id, this.getRequestPracticeId());
    },
    // @vuese
    // Used open prduct modal in update mode
    // @arg Product ID
    // @arg / Product object
    editProductModal(productId, selectedProduct) {
      this.productId = productId;
      this.selectedProduct = selectedProduct;
      if (selectedProduct.subscription) {
        this.selectedProduct.patient = this.patient;
        this.selectedProduct.subscriptionObj = selectedProduct.subscription;
      }
      this.modalAction = 'update';
      this.showModal = true;
    },

    // @vuese
    // Used to remove product from selected list
    // @arg Product object
    removeProduct(value) {
      this.showModal = false;
      const productIndex = this.selectedProducts.findIndex(product => {
        return product.variant_graph_id == value.variant_graph_id;
      });
      if (productIndex !== -1) {
        const localProductId = this.selectedProducts[productIndex].id;
        if (localProductId) {
          this.loading = true;
          RegimenService.deleteProduct(
            this.regimenId,
            localProductId,
            this.getRequestPracticeId()
          )
            .then(() => {
              this.selectedProducts.splice(productIndex, 1);
              this.loading = false;
            });
        } else {
          this.selectedProducts.splice(productIndex, 1);
        }
      }
    },

    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? `/${this.patient.practice.id}` : '';
    },
    // @vuese
    // Used to return Subscription pill
    // @arg Subscription object
    getSubscriptionPill(subscription) {
      return subscription.status == 'cancelled' ? 'Unsubscribed' : 'Subscribed';
    }
  }
};
</script>