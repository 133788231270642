<template>
  <div class="front-wrapper">
    <Header 
      :regimen="regimen"
    />
    <div class="frontend-main">
      <div class="container container-md-xs">
        <div class="steps-wrap">
          <div class="step passed">
            <p>Enter Regimen Information</p>
            <span class="step-marker" />
          </div>

          <div class="step current">
            <p>Create Preset Regimen</p>
            <span class="step-marker" />
          </div>

          <div class="step">
            <p>Review</p>
            <span class="step-marker" />
          </div>
        </div>
      </div>
      <!-- /. steps-wrap -->

      <div class="container container-md-lg">
        <div class="row align-items-baseline">
          <div class="col-md-12">
            <h3 class="mb-sm-3 mb-1">
              Create Preset Regimen
            </h3>
            <p>
              <router-link 
                :to="
                  regimen.id ? 
                    {
                      name: 'RegimenUpdateInfo',
                      params: {
                        id: regimen.id
                      }
                    }
                    :
                    {
                      name: 'RegimenInfo'
                    }"
              >
                <small><i class="fas fa-arrow-left" /></small> Back
              </router-link>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-7">
            <BuildRegimen 
              :open-product-modal="openProductModal"
              :selected-products="regimen.products"
              :regimen="regimen"
            />
          </div>

          <!-- side column -->
          <div class="col-md-5">
            <h4>Preset Regimen</h4>
            <p>Add Products to build your preset regimen</p>
            <SelectedProductList 
              v-model="regimen.products"
              :used-drag-handle="true"
              :helper-class="'sortable-box-wrapper'"
              :hide-sortable-ghost="false"
              :should-cancel-start="shouldCancelStartFunc"
              :loading="loading"
              :selected-products="regimen.products"
              :products-error="productsError"
              @create="goToReview"
              @sort-start="sortStart"
              @sort-end="sortEnd"
            />
          </div>
          <!-- /. row-layout-370 -->
        </div>
      </div>
    </div>
    <!--/. frontend-main -->

    <Modal 
      :show-modal="showModal" 
      :modal-type="'popup-lg'"
      modal-id="add_product"
      @hide-modal-window="showModal = false"
    >
      <ProductModal
        :key="showModal"
        :product-id="productId"
        :modal-action="modalAction"
        :selected-product="selectedProduct"
        @remove-product="removeProduct"
        @hide-modal-window="showModal=false"
        @product-selected="productSelected"
      />
    </Modal>

    <div
      v-if="showModal"
      class="modal-backdrop fade show"
    />
  </div>
  <!--/. frontend-wrap -->
</template>

<script>
import { mapGetters } from 'vuex';
import Header from './Header';
import BuildRegimen from '../../dashboard/partials/BuildRegimen';
import Modal from '../../dashboard/partials/Modal';
import ProductModal from '../../dashboard/partials/ProductModal';
import { formatMoney, renderRecommendationType } from '@/helper';
import { RegimenService } from '@/services/api.service';
import SelectedProductList from '@/views/components/SelectedProductList';


// @group Preset Regimen
export default {
  name: 'RegimenCreatePreset',
  components: {
    BuildRegimen,
    Modal,
    ProductModal,
    Header,
    SelectedProductList
  },
  props: {
    // @vuese
    // Regimen object
    regimen: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      showModal: false,
      productId: null,
      selectedProduct: null,
      modalAction: 'create',
      productsError: '',
    };
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ])
  },
  watch: {
  },
  beforeMount() {
    if (this.regimen.name.length == 0 || this.regimen.tags.length == 0) {
      const id = this.$route.params.id;
      if (id) {
        this.$router.push(`/dashboard/regimen/update/info/${id}`);
      } else {
        this.$router.push('/dashboard/regimen/create/info');
      }
    }
  },
  methods: {
    renderRecommendationType,
    // @vuese
    // Used to chenge the route, and validate if we have that we have at 
    // least one product
    goToReview() {
      if (this.regimen.products.length) {
        this.productsError = '';
        if (this.regimen.id) this.$router.push({name: 'RegimenUpdateReview', params: {id: this.regimen.id}});
        else this.$router.push({name: 'CreateReview'});
      } else {
        this.productsError = 'Please add at least one product';
      }
    },
    // @vuese
    // Used to calculate subtotal of selected products
    calcSubtotal() {
      return this.regimen.products.reduce((sum, product) => {
        return sum + parseFloat(product.price);
      }, 0);
    },
    // @vuese
    // Used format money
    // @arg Price value
    formatMoney (val) {
      return formatMoney(val);
    },
    // @vuese
    // Used to show if product already selected
    // @arg product object
    productSelected(value) {
      this.showModal = false;
      const productIndex = this.regimen.products.findIndex(product => {
        return product.variant_graph_id == value.variant_graph_id;
      });
      if (productIndex !== -1) {
        this.regimen.products.splice(productIndex, 1, value);
      } else {
        this.regimen.products.push(value);
      }
      this.productsError = '';
    },
    // @vuese
    // Used to remove product from selected list
    removeProduct(value) {
      this.showModal = false;
      const productIndex = this.regimen.products.findIndex(product => {
        return product.variant_graph_id == value.variant_graph_id;
      });
      if (productIndex !== -1) {
        const localProductId = this.regimen.products[productIndex].id;
        if (localProductId) {
          this.loading = true;
          RegimenService.deleteProduct(this.$route.params.id, localProductId, this.getRequestPracticeId())
            .then(() => {
              this.regimen.products.splice(productIndex, 1);
              this.loading = false;
            });
        } else {
          this.regimen.products.splice(productIndex, 1);
        }
      }
    },
    // @vuese
    // Used to pass product to modal and show modal
    // @arg Product Id
    openProductModal(productId) {
      this.productId = productId;
      this.selectedProduct = null;
      this.modalAction = 'create';
      this.showModal = true;
    },
    // @vuese
    // Used to edit product modal
    // @arg Product Id
    // @arg / Selectecd product
    editProductModal(productId, selectedProduct) {
      this.productId = productId;
      this.selectedProduct = selectedProduct;
      this.modalAction = 'update';
      this.showModal = true;
    },
    // @vuese
    // Used to get practice ID for super admin user
    getRequestPracticeId() {
      return this.isRole('super_admin') ? 
        this.regimen.practice ? 
          `/${this.regimen.practice.id}` : 
          this.currentUser.practice.id : '';
    },
    // @vuese
    // Used to add class to node when start dragging
    // @arg Node event
    sortStart(e){
      e.node.classList.add('dragging');
    },
    // @vuese
    // Used to remove class from nodes with class `dragging` when sort end
    sortEnd(){
      document.querySelector('.dragging').classList.remove('dragging');
    },
    // @vuese
    // Used to stop draging if it is not drag icon
    // @arg Node event
    shouldCancelStartFunc(e){
      if(e.target.hasAttribute('data-index')) {
        let product = this.regimen.products[e.target.dataset.index];
        this.editProductModal(product.shopify_product_graph_id, product);
        return true;
      } else {
        if(e.target.classList.contains('icon') || e.target.parentNode.classList.contains('icon'))
          return false;
        else 
          return true;
      }
    }
  }
};
</script>