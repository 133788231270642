var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"front-wrapper"},[_c('Header',{attrs:{"regimen":_vm.regimen}}),_vm._v(" "),_c('div',{staticClass:"frontend-main"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"container container-md-lg"},[_c('div',{staticClass:"row align-items-baseline"},[_c('div',{staticClass:"col-md-12"},[_c('h3',{staticClass:"mb-sm-3 mb-1"},[_vm._v("\n            Create Preset Regimen\n          ")]),_vm._v(" "),_c('p',[_c('router-link',{attrs:{"to":_vm.regimen.id ? 
                  {
                    name: 'RegimenUpdateInfo',
                    params: {
                      id: _vm.regimen.id
                    }
                  }
                  :
                  {
                    name: 'RegimenInfo'
                  }}},[_c('small',[_c('i',{staticClass:"fas fa-arrow-left"})]),_vm._v(" Back\n            ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7"},[_c('BuildRegimen',{attrs:{"open-product-modal":_vm.openProductModal,"selected-products":_vm.regimen.products,"regimen":_vm.regimen}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-5"},[_c('h4',[_vm._v("Preset Regimen")]),_vm._v(" "),_c('p',[_vm._v("Add Products to build your preset regimen")]),_vm._v(" "),_c('SelectedProductList',{attrs:{"used-drag-handle":true,"helper-class":'sortable-box-wrapper',"hide-sortable-ghost":false,"should-cancel-start":_vm.shouldCancelStartFunc,"loading":_vm.loading,"selected-products":_vm.regimen.products,"products-error":_vm.productsError},on:{"create":_vm.goToReview,"sort-start":_vm.sortStart,"sort-end":_vm.sortEnd},model:{value:(_vm.regimen.products),callback:function ($$v) {_vm.$set(_vm.regimen, "products", $$v)},expression:"regimen.products"}})],1)])])]),_vm._v(" "),_c('Modal',{attrs:{"show-modal":_vm.showModal,"modal-type":'popup-lg',"modal-id":"add_product"},on:{"hide-modal-window":function($event){_vm.showModal = false}}},[_c('ProductModal',{key:_vm.showModal,attrs:{"product-id":_vm.productId,"modal-action":_vm.modalAction,"selected-product":_vm.selectedProduct},on:{"remove-product":_vm.removeProduct,"hide-modal-window":function($event){_vm.showModal=false},"product-selected":_vm.productSelected}})],1),_vm._v(" "),(_vm.showModal)?_c('div',{staticClass:"modal-backdrop fade show"}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container container-md-xs"},[_c('div',{staticClass:"steps-wrap"},[_c('div',{staticClass:"step passed"},[_c('p',[_vm._v("Enter Regimen Information")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})]),_vm._v(" "),_c('div',{staticClass:"step current"},[_c('p',[_vm._v("Create Preset Regimen")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})]),_vm._v(" "),_c('div',{staticClass:"step"},[_c('p',[_vm._v("Review")]),_vm._v(" "),_c('span',{staticClass:"step-marker"})])])])}]

export { render, staticRenderFns }