<template>
  <div class="front-wrapper">
    <Header 
      :regimen="regimen"
    />
    <div class="frontend-main">
      <div class="container container-md-xs">
        <div class="steps-wrap">
          <div class="step current">
            <p>Enter Regimen Information</p>
            <span class="step-marker" />
          </div>

          <div class="step">
            <p>Create Preset Regimen</p>
            <span class="step-marker" />
          </div>
          
          <div class="step">
            <p>Review</p>
            <span class="step-marker" />
          </div>
        </div>
      </div>
      <!-- /. steps-wrap -->
      
      <div class="container container-md-lg">
        <div class="row row-layout-370 align-items-baseline">
          <div class="col-md-7">
            <h3 class="mb-sm-3 mb-1">
              Enter Regimen Information
            </h3>
          </div>
          <div class="col-md-5">
            <h5>Enter Information for Your Preset Regimen</h5>
          </div>
        </div>
        <ValidationObserver 
          ref="observer" 
          v-slot="{ invalid }" 
          tag="form" 
          @submit.prevent="enterInfo"
        >
          <div class="row row-layout-370">
            <div class="col-md-7">
              <div class="d-box with-lpad pb-4">
                <div class="row form form-gray">
                  <div class="col-sm-4 form-group mb-0">
                    <label 
                      class="form-check-label" 
                      for="p_name"
                    >NAME OF REGIMEN</label>
                  </div>
                  <div class="col-sm-8 form-group">
                    <validation-provider 
                      v-slot="{ errors }" 
                      name="NAME OF REGIMEN" 
                      rules="required"
                    >
                      <input 
                        id="p_name" 
                        v-model="regimen.name"
                        type="text" 
                        placeholder="Acne Prone Regimen"
                        :class="errors.length ? 'error' : ''"
                      >
                      <p 
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2"
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>

                  <div class="col-sm-4 form-group mb-0">
                    <label 
                      class="form-check-label" 
                      for="d_regimen"
                    >
                      DESCRIPTION OF REGIMEN
                    </label>
                  </div>
                  <div class="col-sm-8 form-group">
                    <validation-provider 
                      v-slot="{ errors }" 
                      name="DESCRIPTION OF REGIMEN" 
                      rules="required"
                    >
                      <textarea 
                        id="d_regimen" 
                        v-model="regimen.description"
                        name="d_regimen" 
                        placeholder="Enter your description here..." 
                        :class="errors.length ? 'error' : ''"
                      />
                      <p 
                        v-for="(err, errKey) in errors"
                        :key="errKey"
                        class="validation error mt-2" 
                      >
                        {{ err }}
                      </p>
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>

                  <!-- Payment method -->
                  <div class="col-xl-2 form-group mb-0">
                    <label class="form-check-label">PRIMARY CONCERN</label>
                  </div>
                  <div class="col-xl-10">
                    <div class="row">
                      <div 
                        v-if="practice"
                        v-for="tag in regimenTags"
                        :key="tag.name"
                        class="col-xl-4 col-sm-6"
                      >
                        <input
                          :id="tag.name"
                          type="checkbox" 
                          :checked="tagChacked(tag.name)"
                          @change="handleTag(tag.name)"
                        >
                        <label :for="tag.name">
                          {{ tag.value }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <p 
                    v-if="tagError"
                    class="validation error mt-2" 
                  >
                    {{ tagError }}
                  </p>
                </div>
                <!-- /. row.form -->
              </div>
            </div>

            <!-- side column -->
            <div class="col-md-5 aside-sticky">
              <div class="d-box with-lpad">
                <p>Start building your preset regimen to easily recommend products to your clients.</p>
                <p>&nbsp;</p>
                <button 
                  id="next_step" 
                  class="btn btn-primary"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <!-- /. row-layout-370 -->
        </ValidationObserver>
      </div>
    </div>
    <!--/. frontend-main -->
  </div>
  <!--/. frontend-wrap -->
</template>

<script>
import Header from './Header';
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { tags } from '@/store/regimen/initialObject';
import { PracticeService } from '@/services/api.service';
// Install rules
extend('required', required);

email['message'] = 'Please enter a valid email address';
extend('email', email);

// @vuese
// @group Preset Regimen
export default {
  name: 'RegimenInfo',
  components: {
    Header,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    regimen: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      regimenTags: tags,
      tagError: '',
      practice: null
    };
  },
  created() {
    this.loadPractice(); // Call the loadPractice method when the component is created
  },
  methods: {
    // @vuese
    // Used to validate if all needed data entered and then redirect to other page
    async enterInfo() {
      if (this.regimen.tags.length) {
        this.tagError = '';
      } else {
        this.tagError = 'Primary Concern is required.';
      }

      if (await this.isValid()) {
        if (this.regimen.id) this.$router.push({name: 'RegimenUpdatePreset', params: {id: this.regimen.id}});
        else this.$router.push({name: 'CreatePreset'});
      }
    },
    // @vuese
    // Used to validate all data
    async isValid() {
      return await this.$refs.observer.validate() && this.tagError == '';
    },
    // @vuese
    // Used to add and remove tags from regimen
    // @arg Tag key
    handleTag(tagValue) {
      const tagIndex = this.regimen.tags.findIndex(tag => {
        return tag == tagValue;
      });
      if (tagIndex != -1) {
        this.regimen.tags = []
      } else {
        this.regimen.tags = [tagValue]
        this.tagError = '';
      }

      if(!this.regimen.tags.length){
        this.tagError = 'Primary Concern is required.';
      }
    },
    // @vuese
    // Used to check if tag alreadt added to regimen
    // @arg Tag key
    tagChacked(tagValue) {
      return this.regimen.tags.find(tag => tag == tagValue);
    },

    loadPractice(){ 
      let practicePromise = this.currentUser.practice
        ? PracticeService.getPracticeBy(this.currentUser.practice.id)
        : PracticeService.getPracticeBy();

      practicePromise
        .then(({ data }) => {
          if (data && data.practice) {
            console.log(data)
            this.practice = data.practice;
          }
        })
        .catch((error) => {
          console.error('Error fetching practice:', error);
        });
    }
  }
};
</script>